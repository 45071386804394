import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { MdOutlineLogin } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(loginUser: { email: $email, password: $password }) {
      accessToken
      role
    }
  }
`;

const PasswordForm = ({ userEmail, setIsActive }) => {
  const navigate = useNavigate();
  const [login, { loading, error }] = useMutation(LOGIN_MUTATION);
  const [adminError, setAdminError] = useState('');
  const [showResetButton, setShowResetButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { fetchMe } = useAuth();

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { data } = await login({
          variables: {
            email: userEmail,
            password: values.password,
          },
        });
        console.log(data);
        if (data?.login?.role === 'super-admin') {
          localStorage.setItem('accessToken', data.login.accessToken);
          fetchMe();
          navigate(0);
        } else {
          setAdminError('Only admins can access the dashboard.');
        }
      } catch (error) {
        setShowResetButton(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col px-9 py-6 border border-1 border-[rgba(0,0,0,0.1)] bg-[rgba(0,0,0,0.05)] rounded-md">
          <span className="text-[rgba(0,0,0,0.5)] mb-3">
            Please enter password for your account
          </span>
          <div className="relative">
            <input
              className={`focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent w-[362px] h-12 rounded-md px-4 pr-10 border border-1 text-sm bg-white mb-2 ${
                (formik.touched.password && formik.errors.password) ||
                adminError
                  ? 'border-[red]'
                  : 'border-[rgba(0,0,0,0.1)]'
              }`}
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <MdOutlineRemoveRedEye
              className="absolute right-3 bottom-[42px] cursor-pointer text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            />
            <div className="h-5">
              {error ? (
                <div className="text-red-500 text-sm">{error.message}</div>
              ) : null}
              {(formik.touched.password && formik.errors.password) ||
              adminError ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.password || adminError}
                </div>
              ) : null}
            </div>
          </div>
          <button
            className="flex flex-1 flex-row items-center justify-center font-semibold text-sm bg-[rgba(0,0,0,0.05)] rounded-full w-full py-4 mt-4"
            type="submit"
            disabled={formik.isSubmitting || loading}
          >
            <MdOutlineLogin size={18} className="mr-2" />
            {formik.isSubmitting || loading ? 'Logging in...' : 'Login'}
          </button>
          {showResetButton && (
            <button
              type="button"
              onClick={() => navigate('/forgetPassword')}
              className="flex flex-1 flex-row items-center justify-center font-semibold text-sm bg-[rgba(0,0,0,0.05)] rounded-full w-full py-4 mt-2"
              disabled={formik.isSubmitting || loading}
            >
              Reset Password
            </button>
          )}
          <button
            type="button"
            className="flex flex-1 flex-row items-center justify-center font-semibold text-sm bg-[rgba(0,0,0,0.05)] rounded-full w-full py-4 mt-2"
            onClick={() => setIsActive(false)}
            disabled={formik.isSubmitting || loading}
          >
            Go Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordForm;
