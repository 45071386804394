import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiNewspaperFill } from 'react-icons/ri';
import { FaAddressCard } from 'react-icons/fa';
import { LuLogOut } from 'react-icons/lu';
import PermitsTable from '../components/PermitsTable';
import CitationsTable from '../components/CitationsTable';
import { useAuth } from '../context/AuthContext';

const Home = () => {
  const navigate = useNavigate();
  const { setCurrentUser, currentUser } = useAuth();
  const [selectedTab, setSelectedTab] = useState('Citations');
  const permitsEnabled =
    currentUser?.municipality?.citationLayout?.enablePermits;

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      setCurrentUser(null);
      navigate('/auth');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  const handleTabClick = tab => {
    setSelectedTab(tab);
  };

  const HeaderButtons = () => {
    return (
      <div className="bg-[rgba(0,0,0,0.05)] flex flex-row border-2 rounded-full border-transparent">
        <button
          onClick={() => handleTabClick('Citations')}
          className={`flex flex-row rounded-full px-9 py-3 items-center transition-colors duration-300 ${selectedTab === 'Citations' ? 'bg-[white]' : 'bg-transparent hover:bg-[rgba(0,0,0,0.05)]'}`}
        >
          <RiNewspaperFill />
          <span className="text-sm font-bold ml-2">Citations</span>
        </button>
        {permitsEnabled && (
          <button
            onClick={() => handleTabClick('Permits')}
            className={`flex flex-row rounded-full px-9 py-3 items-center transition-colors duration-300 ${selectedTab === 'Permits' ? 'bg-[white]' : 'bg-transparent hover:bg-[rgba(0,0,0,0.05)]'}`}
          >
            <FaAddressCard />
            <span className="text-sm font-bold ml-2">Permits</span>
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="bg-[rgba(0,0,0,0.03)] h-[84px] flex flex-row px-10 py-4 justify-between absolute w-full z-30">
        <div className="flex flex-row items-center">
          <img
            src={require('../assets/enforceLogo.png')}
            className="w-[45px] h-[45px] object-contain"
            alt="Enforce logo"
          />
          <span className="text-lg text-black font-bold ml-3">Dashboard</span>
        </div>
        <HeaderButtons />
        <button
          onClick={handleSignOut}
          className="flex flex-row rounded-full bg-[rgba(0,0,0,0.05)] px-9 py-3 items-center hover:bg-[rgba(0,0,0,0.15)]"
        >
          <LuLogOut />
          <span className="text-sm font-bold ml-2">Logout</span>
        </button>
      </div>
      {selectedTab === 'Citations' ? <CitationsTable /> : <PermitsTable />}
    </div>
  );
};

export default Home;
