import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table } from 'flowbite-react';
import { FaAddressCard, FaSort } from 'react-icons/fa';
import AddPermitModal from './AddPermitModal';
import ConfirmationModal from './ConfirmationModal';
import { tableTheme, toastConfig } from '../utils/constants';
import { MdOutlineTimer } from 'react-icons/md';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import TableLoadingComponent from './TableLoadingComponent';
import { formatStatus } from '../utils/helperFunctions';

const UPDATE_PERMIT_STATUS = gql`
  mutation UpdatePermitStatus($permitId: String!, $permitStatus: PermitStatus) {
    updatePermitStatus(
      updatePermitInput: { permitStatus: $permitStatus, permitId: $permitId }
    ) {
      id
    }
  }
`;
const GET_ALL_PERMITS = gql`
  query GetAllPermits(
    $municipalityId: String!
    $page: Int!
    $limit: Int!
    $sortOptions: [SortOptionInput!]
  ) {
    getAllPermits(
      getAllPermitsInput: {
        municipalityId: $municipalityId
        pagination: { page: $page, limit: $limit }
        sortOptions: $sortOptions
      }
    ) {
      permits {
        id
        permitNumber
        color
        licensePlateNumber
        licensePlateState
        make
        modal
        fee
        ownerCity
        ownerAddress
        ownerName
        term
        ownerZip
        ownerState
        ownerDOB
        effectiveDate
        expirationDate
        permitStatus
        municipalityId
        parkingPermitId
        createdAt
        updatedAt
      }
      pagination {
        page
        total
      }
    }
  }
`;

const PermitsTable = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const tableContainerRef = useRef();
  const [sortOptions, setSortOption] = useState({
    column: 'permitNumber',
    direction: 'DESC',
  });
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [permitData, setPermitData] = useState();
  const [newPermitId, setNewPermitId] = useState();
  const { currentUser } = useAuth();
  const [hasMore, setHasMore] = useState(true);
  const [permitsArray, setPermitsArray] = useState([]);
  const [updatePermitStatus, { loading: updatingStatus }] =
    useMutation(UPDATE_PERMIT_STATUS);
  const [getAllPermits] = useLazyQuery(GET_ALL_PERMITS, {
    fetchPolicy: 'network-only',
  });

  const fetchPermits = async () => {
    setPage(1);
    try {
      setLoading(true);
      const { data } = await getAllPermits({
        variables: {
          municipalityId: currentUser.municipalityId,
          page: 1,
          limit: limit,
          sortOptions: [sortOptions],
        },
      });
      setPermitsArray(data?.getAllPermits?.permits);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPermits();
  }, []);
  const refetch = sortConfig => {
    fetchPermits(sortConfig);
  };
  const handleScroll = useCallback(async () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight >= container.scrollHeight &&
      hasMore
    ) {
      try {
        setFetchingMore(true);
        setPage(prev => prev + 1);
        const { data } = await getAllPermits({
          variables: {
            page: page + 1,
            municipalityId: currentUser.municipalityId,
            limit: limit,
            sortOptions: [sortOptions],
          },
        });
        setPermitsArray(prev => [...prev, ...data.getAllPermits.permits]);
        setHasMore(
          data.getAllPermits.permits.length > 0 &&
            data.getAllPermits.permits.length <
              data.getAllPermits.pagination.total
        );
      } catch (error) {
      } finally {
        setFetchingMore(false);
      }
    }
  }, [currentUser.municipalityId, getAllPermits, hasMore, page, sortOptions]);
  useEffect(() => {
    if (newPermitId) {
      setTimeout(() => {
        setNewPermitId(null);
      }, 3000);
    }
  }, [newPermitId]);
  useEffect(() => {
    const container = tableContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [page, hasMore, sortOptions, handleScroll]);

  const renderParkingPermit = parkingPermitId => {
    const selectedZone = currentUser?.municipality?.parkingPermits.find(
      p => p.id === parkingPermitId
    );
    return <Table.Cell>{selectedZone?.zone || '---'}</Table.Cell>;
  };
  const sortPermits = key => {
    if (sortOptions.column === key) {
      if (sortOptions.direction === 'DESC') {
        setSortOption({
          column: key,
          direction: 'ASC',
        });
        refetch({
          column: key,
          direction: 'ASC',
        });
      } else {
        setSortOption({
          column: key,
          direction: 'DESC',
        });
        refetch({
          column: key,
          direction: 'DESC',
        });
      }
    } else {
      setSortOption({
        column: key,
        direction: 'ASC',
      });
      refetch({
        column: key,
        direction: 'ASC',
      });
    }
  };
  const makePermitStatusExpire = async () => {
    try {
      await updatePermitStatus({
        variables: { permitId: permitData.id, permitStatus: 'EXPIRED' },
      });
      refetch();
      setModalVisible(false);
      toast.success('Permit updated successfully', toastConfig);
    } catch (error) {
      toast.error('Error updating permit', toastConfig);
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setAddModalVisible(false);
        setModalVisible(false);
        document.activeElement.blur();
      }
    };
    if (addModalVisible || modalVisible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [addModalVisible, modalVisible]);

  return (
    <div className="px-10 pt-20">
      <div className="flex flex-row justify-end py-6">
        <button
          onClick={() => setAddModalVisible(true)}
          className="bg-[#FACE4F] flex flex-row items-center px-8 py-3 rounded-full hover:bg-[#D9B345]"
        >
          <FaAddressCard />
          <span className="pl-3 text-sm font-bold">Add Permit</span>
        </button>
      </div>
      <div
        ref={tableContainerRef}
        className="overflow-auto mb-5"
        style={{ maxHeight: 'calc(100vh - 195px)' }}
      >
        {!loading && permitsArray.length === 0 ? (
          <div
            style={{ height: 'calc(100vh - 195px)' }}
            className="bg-[rgba(0,0,0,0.02)] flex justify-center items-center flex-1"
          >
            <span className=" text-lg text-[#374151] font-bold w-[180px] text-center">
              You don’t have any permits yet
            </span>
          </div>
        ) : (
          <Table id="permit-table" theme={tableTheme}>
            <Table.Head>
              <Table.HeadCell
                onClick={() => sortPermits('permitNumber')}
                className="cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Permit #</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('ownerName')}
                className="cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Owner Name</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Owner DOB
              </Table.HeadCell>
              <Table.HeadCell className="sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Owner’s Address
              </Table.HeadCell>
              <Table.HeadCell className="sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Vehicle
              </Table.HeadCell>
              <Table.HeadCell className="sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Plate
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('parkingPermitId')}
                className="cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Zone</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('effectiveDate')}
                className="cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Issued Date</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('expirationDate')}
                className="cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Expiration</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className=" sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Fee
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('permitStatus')}
                className="cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Status</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md" />
            </Table.Head>
            <Table.Body>
              {loading ? (
                <TableLoadingComponent numberofColumns={11} />
              ) : (
                permitsArray.map(permit => {
                  const isHighlighted = newPermitId === permit.id;
                  return (
                    <Table.Row
                      className={`group even:bg-[rgba(0,0,0,0.05)] hover:bg-[rgba(0,0,0,0.1)] ${
                        isHighlighted ? 'animate-fadeHighlight' : ''
                      }`}
                      style={{
                        animation: isHighlighted
                          ? 'fadeHighlight 3s ease-in-out'
                          : 'none',
                      }}
                    >
                      <Table.Cell>{permit?.permitNumber}</Table.Cell>
                      <Table.Cell>{permit?.ownerName || '---'}</Table.Cell>
                      <Table.Cell className="w-28">
                        {permit?.ownerDOB || '---'}
                      </Table.Cell>
                      <Table.Cell>
                        {permit?.ownerAddress ||
                        permit?.ownerCity ||
                        permit?.ownerZip ||
                        permit?.ownerState
                          ? `${permit?.ownerAddress || ''} ${permit?.ownerCity || ''} ${permit?.ownerState || ''} ${permit?.ownerZip || ''}`
                          : '---'}
                      </Table.Cell>
                      <Table.Cell>
                        {!permit?.color && !permit?.make && !permit?.model
                          ? '---'
                          : `${permit?.color || ''} ${permit?.make || ''} ${permit?.model || ''}`}
                      </Table.Cell>
                      <Table.Cell className="uppercase">
                        {permit?.licensePlateNumber || permit?.licensePlateState
                          ? `${permit?.licensePlateNumber} ${permit?.licensePlateState}`
                          : '---'}
                      </Table.Cell>
                      {renderParkingPermit(permit?.parkingPermitId)}
                      <Table.Cell className="w-28">
                        {permit?.effectiveDate || '---'}
                      </Table.Cell>
                      <Table.Cell className="w-28">
                        {permit?.expirationDate || '---'}
                      </Table.Cell>
                      <Table.Cell>{permit?.fee || '---'}</Table.Cell>
                      <Table.Cell>
                        {formatStatus(permit?.permitStatus) || '---'}
                      </Table.Cell>
                      <Table.Cell className="cursor-pointer">
                        {permit?.permitStatus !== 'EXPIRED' && (
                          <MdOutlineTimer
                            className="hover:text-[black]"
                            size={20}
                            onClick={() => {
                              setModalVisible(true);
                              setPermitData(permit);
                            }}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
              {fetchingMore && (
                <TableLoadingComponent numberofRows={4} numberofColumns={10} />
              )}
            </Table.Body>
          </Table>
        )}
      </div>
      <ConfirmationModal
        loading={updatingStatus}
        title="Expire Permit?"
        description="Are you sure you want to expire this permit?"
        subDescription="It will remain in the records, but its expiration date and status will be changed to expired on today’s date."
        isOpen={modalVisible}
        onClose={() => {
          setPermitData(null);
          setModalVisible(false);
        }}
        onConfirm={makePermitStatusExpire}
        buttonTitle="Expire"
        icon={<MdOutlineTimer className="hover:text-[black] m-1" size={20} />}
      />
      <AddPermitModal
        setNewPermitId={setNewPermitId}
        isOpen={addModalVisible}
        onClose={() => {
          setAddModalVisible(false);
        }}
        refetch={refetch}
      />
    </div>
  );
};

export default PermitsTable;
