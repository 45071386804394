const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  subDescription,
  buttonTitle,
  loading,
  icon,
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)] bg-opacity-75"
      onClick={onClose}
    >
      <div
        onClick={e => e.stopPropagation()}
        className="bg-white rounded-md shadow-lg p-9 w-1/3"
      >
        <h2 className="text-lg font-bold text-center mb-9">{title}</h2>
        <p className="text-sm text-[rgba(0,0,0,0.5)] text-center">
          {description}
        </p>
        <p className="text-sm text-[rgba(0,0,0,0.5)] text-center">
          {subDescription ||
            'It will be permanently deleted, and it can not be restored.'}
        </p>
        <div className="h-[1px] bg-[rgba(0,0,0,0.1)] my-9" />
        <div className="flex justify-center space-x-4">
          <button
            onClick={onClose}
            className="w-full py-2 bg-[rgba(0,0,0,0.05)] text-gray-800 font-semibold rounded-full hover:bg-[rgba(0,0,0,0.15)]"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="w-full py-2 bg-[#F75474] text-white rounded-full font-semibold hover:bg-[#DB4865] flex flex-row items-center justify-center"
          >
            <span className="mr-2 flex flex-row justify-center items-center">
              {icon}
              {loading
                ? buttonTitle === 'Delete'
                  ? 'Deleting...'
                  : 'Expiring...'
                : buttonTitle || 'Delete'}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
