import { ErrorMessage, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { MdOutlineFileUpload, MdOutlineImage } from 'react-icons/md';

const CustomFileUpload = () => {
  const { setFieldValue } = useFormikContext();
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);

  const handleFileChange = event => {
    const file = event.currentTarget.files[0];

    if (file) {
      const isValidType = file.type.startsWith('image/');
      const isValidSize = file.size <= 10 * 1024 * 1024; // 10 MB

      if (!isValidType) {
        setFileError('Please upload an image file.');
        setFieldValue('evidence', null);
        setFileName(null);
        return;
      }

      if (!isValidSize) {
        setFileError('File size must be less than or equal to 10 MB.');
        setFieldValue('evidence', null);
        setFileName(null);
        return;
      }

      setFileError(null);
      setFieldValue('evidence', file);
      setFileName(file.name);
    } else {
      setFieldValue('evidence', null);
      setFileName(null);
    }
  };

  const handleRemoveFile = () => {
    setFieldValue('evidence', null);
    setFileName(null);
    setFileError(null);
  };

  return (
    <div>
      <input
        id="evidence"
        name="evidence"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <div className="flex items-center">
        <label
          htmlFor="evidence"
          className="flex-row flex flex-1 justify-center items-center btn-upload cursor-pointer bg-[rgba(0,0,0,0.05)] text-black py-[10px] px-4 rounded-full font-semibold text-sm hover:bg-[rgba(0,0,0,0.15)]"
        >
          <MdOutlineFileUpload size={20} className="mr-3" />
          Upload Photo
        </label>
        {fileName && (
          <div
            style={{ maxWidth: '50%' }}
            className="py-[10px] px-6 border border-1 border-[rgba(0,0,0,0.05)] ml-2 flex items-center"
          >
            <MdOutlineImage size={20} />
            <span className="file-name ml-3 mr-2 overflow-hidden whitespace-nowrap text-ellipsis">
              {fileName}
            </span>
            <FaTimes
              className="text-black cursor-pointer"
              onClick={handleRemoveFile}
            />
          </div>
        )}
      </div>
      {fileError && (
        <div className="text-red-500 text-xs mt-2">{fileError}</div>
      )}
      <ErrorMessage
        className="text-red-500 text-xs mt-2"
        name="evidence"
        component="div"
      />
    </div>
  );
};

export default CustomFileUpload;
