import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
      message
    }
  }
`;
const ForgetPassword = () => {
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [forgotPassword, { loading, error }] = useMutation(
    FORGOT_PASSWORD_MUTATION
  );

  const sendPasswordResetEmail = async userEmail => {
    try {
      await forgotPassword({
        variables: { email: userEmail },
      });
      setEmailSent(true);
      toast.success('Passoword reset link sent to your email.', toastConfig);
      formik.setSubmitting(false);
    } catch (error) {
      toast.error(error.message || 'Error while logging in', toastConfig);
      formik.setSubmitting(false);
      console.error('Error sending password reset email:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: values => {
      sendPasswordResetEmail(values.email);
    },
  });
  return (
    <div className="flex flex-col justify-center items-center flex-1 h-screen">
      <div className="flex flex-col items-center">
        <img
          src={require('../assets/enforceLogo.png')}
          className="w-[120px] h-[120px] object-contain"
          alt="Enforce logo"
        />
        <span className="text-4xl font-bold my-4">Enforce Dashboard</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col px-9 py-6 border border-1 border-[rgba(0,0,0,0.1)] bg-[rgba(0,0,0,0.05)] rounded-md">
          <span className="text-[rgba(0,0,0,0.5)] mb-3">
            Please enter your work email
          </span>
          <input
            className={`focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent w-[362px] h-12 rounded-md px-4 border border-1 text-sm bg-white mb-2   ${emailSent ? 'border-[#47B403]' : formik.touched.email && formik.errors.email ? 'border-[red]' : 'border-[rgba(0,0,0,0.1)]'}`}
            type="email"
            name="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />

          <div className="h-12">
            {emailSent ? (
              <div className="text-[#47B403] text-sm max-w-[360px]">
                Password reset link sent
              </div>
            ) : null}
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
            {error && (
              <div className="text-red-500 text-sm mt-2">
                {error.message || ' Error checking user status'}
              </div>
            )}
          </div>
          <button
            className="flex flex-1 flex-row items-center justify-center font-semibold text-sm bg-[rgba(0,0,0,0.05)] rounded-full w-full py-4 "
            type="submit"
            disabled={formik.isSubmitting || loading}
          >
            {formik.isSubmitting || loading ? 'Sending...' : 'Reset Password'}
          </button>
          <button
            onClick={() => navigate('/')}
            className="mt-2 flex flex-1 flex-row items-center justify-center font-semibold text-sm bg-[rgba(0,0,0,0.05)] rounded-full w-full py-4 "
            type="button"
            disabled={formik.isSubmitting || loading}
          >
            Go Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
