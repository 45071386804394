import { Table } from 'flowbite-react';
import { Breathing } from 'react-shimmer';

const TableLoadingComponent = ({ numberofRows = 14, numberofColumns }) => {
  return Array(numberofRows)
    .fill(0)
    .map(() => {
      return (
        <Table.Row>
          {Array(numberofColumns)
            .fill(0)
            .map(() => (
              <Table.Cell className="px-0">
                {' '}
                <Breathing height={20} width={100} className="rounded" />
              </Table.Cell>
            ))}
        </Table.Row>
      );
    });
};

export default TableLoadingComponent;
