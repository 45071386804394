import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MdOutlineLogin } from 'react-icons/md';
import { gql, useLazyQuery } from '@apollo/client';

const CHECK_USER_STATUS = gql`
  query CheckUserState($email: String!) {
    checkUserState(email: $email)
  }
`;

const EmailForm = ({ setIsActive, setUserEmail, userEmail }) => {
  const [emailSent, setEmailSent] = useState(false);

  const [checkUserState, { loading, error }] = useLazyQuery(CHECK_USER_STATUS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (!data.checkUserState) {
        setEmailSent(true);
        setIsActive(false);
      } else {
        setIsActive(true);
      }
      setUserEmail(formik.values.email);
      formik.setSubmitting(false);
    },
    onError: () => {
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: userEmail || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: values => {
      checkUserState({ variables: { email: values.email } });
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col px-9 py-6 border border-1 border-[rgba(0,0,0,0.1)] bg-[rgba(0,0,0,0.05)] rounded-md">
          <span className="text-[rgba(0,0,0,0.5)] mb-3">
            Please enter your work email
          </span>
          <input
            className={`focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent w-[362px] h-12 rounded-md px-4 border border-1 text-sm bg-white mb-2   ${emailSent ? 'border-[#47B403]' : formik.touched.email && formik.errors.email ? 'border-[red]' : 'border-[rgba(0,0,0,0.1)]'}`}
            type="email"
            name="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />

          <div className="h-12">
            {emailSent ? (
              <div className="text-[#47B403] text-sm max-w-[360px]">
                Please follow the link in your email to set account password
              </div>
            ) : null}
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
            {error && (
              <div className="text-red-500 text-sm mt-2">
                {error.message || ' Error checking user status'}
              </div>
            )}
          </div>
          <button
            className="flex flex-1 flex-row items-center justify-center font-semibold text-sm bg-[rgba(0,0,0,0.05)] rounded-full w-full py-4 "
            type="submit"
            disabled={formik.isSubmitting || loading}
          >
            <MdOutlineLogin size={18} className="mr-2" />
            {formik.isSubmitting || loading ? 'Checking...' : 'Login'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailForm;
