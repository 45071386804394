import React, { useEffect, useState } from 'react';
import EmailForm from '../components/EmailForm';
import PasswordForm from '../components/PasswordForm';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const [isActive, setIsActive] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  return (
    <div className="flex flex-col justify-center items-center flex-1 h-screen">
      <div className="flex flex-col items-center">
        <img
          src={require('../assets/enforceLogo.png')}
          className="w-[120px] h-[120px] object-contain"
          alt="Enforce logo"
        />
        <span className="text-4xl font-bold my-4">Enforce Dashboard</span>
      </div>
      {isActive && userEmail ? (
        <PasswordForm userEmail={userEmail} setIsActive={setIsActive} />
      ) : (
        <EmailForm
          setIsActive={setIsActive}
          setUserEmail={setUserEmail}
          userEmail={userEmail}
        />
      )}
    </div>
  );
};

export default Auth;
