import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils/constants';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(
      resetPasswordInput: { token: $token, newPassword: $newPassword }
    ) {
      success
      message
    }
  }
`;

const ResetPassword = ({ variant }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  const [resetPassword, { loading, error }] = useMutation(
    RESET_PASSWORD_MUTATION,
    {
      onCompleted: data => {
        toast.success(
          variant === 'setPassword'
            ? 'Password set up successfuly'
            : 'Password reset successfuly',
          toastConfig
        );
        console.log('Password reset successful:', data.resetPassword.message);
        navigate('/');
        formik.resetForm();
        formik.setSubmitting(false);
      },
      onError: () => {
        formik.setSubmitting(false);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      verifyPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Required'),
      verifyPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: values => {
      formik.setSubmitting(true);
      resetPassword({ variables: { token, newPassword: values.newPassword } });
    },
  });
  return (
    <div className="flex flex-col justify-center items-center flex-1 h-screen">
      <div className="flex flex-col items-center">
        <img
          src={require('../assets/enforceLogo.png')}
          className="w-[120px] h-[120px] object-contain"
          alt="Enforce logo"
        />
        <span className="text-4xl font-bold my-4">Enforce Dashboard</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col px-9 py-6 border border-1 border-[rgba(0,0,0,0.1)] bg-[rgba(0,0,0,0.05)] rounded-md">
          <span className="text-[rgba(0,0,0,0.5)] mb-3">
            Please set password for your account
          </span>
          <div className="relative">
            <input
              className={`focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent w-[300px] h-12  rounded-md px-4 border border-1 text-sm bg-white md:w-[362px] ${formik.touched.newPassword && formik.errors.newPassword ? 'border-[red]' : 'border-[rgba(0,0,0,0.1)]'} `}
              type={showPassword ? 'text' : 'password'}
              name="newPassword"
              placeholder="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
            />
            <MdOutlineRemoveRedEye
              className="absolute right-3 bottom-[35px] cursor-pointer text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            />
            <div className="h-5">
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.newPassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative">
            <input
              className={`focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent w-[300px] h-12 rounded-md px-4 border border-1 text-sm bg-white md:w-[362px] ${formik.touched.verifyPassword && formik.errors.verifyPassword ? 'border-[red]' : 'border-[rgba(0,0,0,0.1)]'}`}
              type={showVerifyPassword ? 'text' : 'password'}
              name="verifyPassword"
              placeholder="Repeat Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.verifyPassword}
            />
            <MdOutlineRemoveRedEye
              className="absolute right-3 bottom-[35px] cursor-pointer text-gray-500"
              onClick={() => setShowVerifyPassword(!showVerifyPassword)}
            />
            <div className="h-5">
              {error && (
                <div className="text-red-500 text-sm">
                  Error resetting password: {error.message}
                </div>
              )}
              {formik.touched.verifyPassword && formik.errors.verifyPassword ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.verifyPassword}
                </div>
              ) : null}
            </div>
          </div>
          <button
            className="flex flex-1 flex-row items-center justify-center font-semibold text-sm bg-[rgba(0,0,0,0.05)] rounded-full w-full py-4 mt-4"
            type="submit"
            disabled={formik.isSubmitting || loading}
          >
            {formik.isSubmitting || loading
              ? variant === 'setPassword'
                ? 'Setting...'
                : 'Updating...'
              : variant === 'setPassword'
                ? 'Set Password'
                : 'Reset Password'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
